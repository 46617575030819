<template>
    <transition name="popup" @leave="leave">
        <div class="popup" v-if="isVisible">
            <div class="popup-content" style="background-color: transparent;">
                <button class="close-button" @click="closePopup">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                        <path
                            d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                    </svg>
                </button>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        closePopup() {
            this.$emit('close');
        },
        leave(el, done) {
            el.querySelector('.popup-content').classList.add('popup-leave-active');
            setTimeout(done, 500);
        }
    }
}
</script>

<style scoped>
@keyframes fadeInScale {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fadeOutScale {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.5);
        opacity: 0;
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    width: 80%;
    max-width: 800px;
    max-height: 90%;
    overflow-y: auto;
    animation: fadeInScale 0.8s ease-out;
}

.popup-leave-active {
    background-color: transparent;
    animation: fadeOutScale 0.5s ease-out forwards;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
}
</style>
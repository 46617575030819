<template>
    <button @click = "click" class = 'm-1 bg-blue-500 hover:bg-blue-700 rounded p-2 text-white text-sm'>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="svg" />
        </svg>
        <span class = 'hidden sm:inline-block ml-1'>{{ label }}</span>
    </button>
</template>

<script>
export default {
    name:"IconButton",
    props:{
        label:"",
        svg:""
    },
    methods:{
        click:function(){
            this.$emit('click')
        }
    }
}
</script>
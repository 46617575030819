<template>

    <Title :title = "$t('paymentpage.title')" />

    <div v-show="done">

        <div class = 'md:w-2/3 lg:w-2/5 mx-auto mt-10'>
            
            <div class="text-center my-10">{{ $t('paymentpage.redirect') }}</div>

            <div class="text-center">{{ $t('paymentpage.done') }}</div>

        </div>

    </div>

    <fieldset v-show="!done" :disabled="loading">
        <div class = 'md:w-2/3 lg:w-2/5 mx-auto mt-5'>

            <div class="grid grid-cols-2 gap-4">
                
                <div>{{$t('titleno')}}</div>
                <div class="text-right">{{titleno}}</div>
                
                <div class="col-span-2"><hr></div>
                
                <div>{{$t('paymentpage.quitrenttopay')}}</div>
                <div class="text-right">{{quitrentdue}}</div>

                <div>{{$t('paymentpage.noticefeetopay')}}</div>
                <div class="text-right">{{noticefeedue}}</div>

                <div>{{$t('paymentpage.surchargetopay')}}</div>
                <div class="text-right">{{surchargedue}}</div>

                <div class="col-span-2"><hr></div>

                <div>{{$t('paymentpage.amttopay')}}</div>
                <div><input v-model="paymentamt" ref="paymentamt" @keypress.enter="focusPayerName" class='text-right mb-0 bg-white rounded p-2 shadow focus:outline-none text-black w-full'/></div>
                <div style="margin-top: -10px;" class="col-span-2 text-sm text-right text-red-500 pt-0">* {{$t('paymentpage.minimumAmount')}}</div>


                <div>{{$t('paymentpage.payer')}}</div>
                <div>
                    <input ref="payerName" class='bg-white rounded p-2 shadow focus:outline-none text-gray-700 w-full' 
                        v-model="payerName" required/>
                </div>

                <div>{{$t('paymentpage.email')}}</div>
                <div>
                    <input ref="payerEmail" class='bg-white rounded p-2 shadow focus:outline-none text-gray-700 w-full' 
                        v-model="payerEmail" placeholder="example@email.com" required/>
                </div>
                
            </div>

            <div class="mt-3">
                <IconButton :label = "$t('back')" @click="back" :class="'ml-0'"
                    svg = "M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"/>
                
                <button type="button" :disabled="loading" @click = "requestPayment" class = 'm-1 mr-0 bg-green-700 hover:bg-green-900 rounded px-3 py-2 text-white text-sm float-right'>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class = 'hidden sm:inline-block ml-1'>{{ $t('paymentpage.pay') }}</span>
                </button>

                <img v-if="loading" src = '@/assets/loading-buffering.gif' class = 'mx-auto float-right mr-5 mt-3' style="width: 30px; height: 30px;"/>
            </div>

            
            
        </div>

    </fieldset>

</template>

<script>
import Title from '@/components/Title'
import IconButton from '@/components/IconButton'
import router from '@/router'
import axios from 'axios'
import config from '@/config'

export default {
    data: () => {
        return {
            paymentamt: 0,
            quitrentpayamt: 0,
            noticefeepayamt: 0,
            surchargepayamt: 0,

            remainingamt: 0,
            token: "",
            refno: "",
            // name: "Payment",
            
            titleno: "",
            // amount: 0,
            
            quitrentdue: 0,
            noticefeedue: 0,
            surchargedue: 0,
            totaldue: 0,
            
            payerName: "",
            payerEmail: "",

            loading: false,
            // payFull: true,
            // payOther: false,

            done: false,
        }
    },
    components: { 
        Title, 
        IconButton 
    },
    mounted: function () {
        this.$refs.payerName.focus();
        this.titleno = this.$route.query.titleno;
        // this.amount = this.$route.query.amount;
        this.quitrentdue = this.$route.query.quitrentdue;
        this.noticefeedue = this.$route.query.noticefeedue;
        this.surchargedue = this.$route.query.surchargedue;
        this.totaldue = (Math.round((Number(this.quitrentdue) + Number(this.noticefeedue) + Number(this.surchargedue)) * 100) / 100).toFixed(2);
        this.paymentamt = this.totaldue;
        // this.totaldue = (Math.round((Number(this.amount) + Number(this.amountnoticefee) + Number(this.amountsurcharge)) * 100) / 100).toFixed(2);
        // this.totalamount = (Math.round((Number(this.amountdue)) * 100) / 100).toFixed(2);
    },
    methods: {
        back: function () {
            router.push({ path: '/arrears', query: { titleno: this.titleno } })
        },
        onAmountTypeChange: function (event) {
            var data = event.target.value;

            if (data === "full") {
                this.full = true;
                this.other = false;

                this.$nextTick(() => {
                    this.$refs.payerName.focus();
                });
            }
            else {
                this.full = false;
                this.other = true;

                this.$nextTick(() => {
                    this.$refs.otherAmt.focus();
                });
            }
        },
        focusPayerName() {
            this.$refs.payerName.focus();
        },
        minus(amt1, amt2) {
            return (Math.round((Number(amt1) - Number(amt2)) * 100) / 100).toFixed(2)
        },  
        requestPayment: function () {

            if (this.paymentamt === "") {
                alert("Please enter payment amount");
                this.$refs.paymentamt.focus();
                return;
            }

            if (this.paymentamt < 10) {
                alert("Minimum Payment Amount is RM 10");
                this.$refs.paymentamt.focus();
                return;
            }

            if (this.payerName == "") {
                alert("Please enter payer name");
                this.$refs.payerName.focus();
                return;
            }

            if (this.payerEmail == "") {
                alert("Please enter email");
                this.$refs.payerEmail.focus();
                return;
            }

            // this.remainingamt = this.paymentamt;

            // // offbal notice fee
            // if (Number(this.remainingamt) <= Number(this.noticefeedue)) {
            //     this.noticefeepayamt = this.remainingamt;
            //     this.remainingamt = 0;
            // }
            // else {
            //     this.noticefeepayamt = this.noticefeedue;
            //     this.remainingamt = this.minus(this.remainingamt, this.noticefeedue);
            // }

            // // offbal surcharge
            // if (this.remainingamt > 0) {
            //     if (Number(this.remainingamt) <= Number(this.surchargedue)) {
            //         this.surchargepayamt = this.remainingamt;
            //         this.remainingamt = 0;
            //     }
            //     else {
            //         this.surchargepayamt = this.surchargedue;
            //         this.remainingamt = this.minus(this.remainingamt, this.surchargedue);
            //     }
            // }

            // // offbal quitrent
            // if (this.remainingamt > 0) {
            //     if (Number(this.remainingamt) <= Number(this.quitrentdue)) {
            //         this.quitrentpayamt = this.remainingamt;
            //         this.remainingamt = 0;
            //     }
            //     else {
            //         this.quitrentpayamt = this.quitrentdue;
            //         this.remainingamt = this.minus(this.remainingamt, this.quitrentdue);
            //     }
            // }

            let body = {
                systemId: "QREWEB",
                titleNo: this.titleno,
                email: this.payerEmail,
                payerName: this.payerName,
                paymentAmount: this.paymentamt,
                // quitRentAmount: this.quitrentpayamt,
                // noticeFeeAmount: this.noticefeepayamt,
                // surchargeAmount: this.surchargepayamt,
                // isOtherAmount: this.payOther,
            }

            this.loading = true;

            axios.post(config.FPX_REQUEST_URL, body)
            .then(res => {

                if (res.data.success) {
                    let fpxrefno = res.data.newRefNo;
                    let fpxrefno2 = res.data.newRefNo2;

                    const paygateurl = config.PAYGATE_URL + "?ref1=" + fpxrefno + "&ref2=" + fpxrefno2;

                    window.open(paygateurl, '_blank');

                    this.done = true;
                    
                } else {
                    alert("Something went wrong, please try again later.");
                }
            })
            .catch((err) => {
                console.log(err);
                alert("Something went wrong, please try again later.");
            })
            .finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>
const config = {

    CHECKTITLE_URL: process.env.NODE_ENV === 'production' ? "https://quitrent.jtu.sabah.gov.my/v3/api/check" : 'http://localhost/v3/api/check',
    QUERY_URL: process.env.NODE_ENV === 'production' ? "https://quitrent.jtu.sabah.gov.my/v3/api/query" : 'http://localhost/v3/api/query',
    STATEMENTPDF_URL: process.env.NODE_ENV === 'production' ? "https://quitrent.jtu.sabah.gov.my/v3/api/statementPdf" : 'http://localhost/v3/api/statementPdf',
    CHKRECEIPT_URL: process.env.NODE_ENV === 'production' ? "https://quitrent.jtu.sabah.gov.my/v3/api/check-receipt" : 'http://localhost/v3/api/check-receipt',
    // RECEIPTPDF_URL: process.env.NODE_ENV === 'production' ? "https://quitrent.jtu.sabah.gov.my/v3/api/receiptPdf" : 'http://localhost/v3/api/receiptPdf',
    
    FPX_REQUEST_URL: process.env.NODE_ENV === 'production' ? "https://quitrent.jtu.sabah.gov.my/v3/api/payment/fpx/request" : 'http://localhost/v3/api/payment/fpx/request',
    FPX_STATUS_URL: process.env.NODE_ENV === 'production' ? "https://quitrent.jtu.sabah.gov.my/v3/api/payment/fpx/status" : 'http://localhost/v3/api/payment/fpx/status',
    FPX_RECEIPT_URL: process.env.NODE_ENV === 'production' ? "https://quitrent.jtu.sabah.gov.my/v3/api/payment/fpx/receipt" : 'http://localhost/v3/api/payment/fpx/receipt',
    PAYGATE_URL: process.env.NODE_ENV === 'production' ? "https://jtupay.sabah.gov.my/PayGateJTU/index.cfm" : 'https://jtupay.sabah.gov.my/PayGateJTU/index.cfm',

}

export default config;
<template>
  <Header/>
  <div class = 'pt-10'>
    <router-view/>
  </div>
  <Footer/>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  components:{
    Header,
    Footer,
  },
  created:function(){
    document.title = this.$t('title')
  },
  watch:{
    '$i18n.locale':function(){
      document.title = this.$t('title')
    }
  }
}
</script>


<style>

</style>

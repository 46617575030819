<template>
    <img v-if="showspinner" src='@/assets/loading-buffering.gif' class='h-20 w-20 mx-auto mt-10' />

    <div v-if="!showspinner" >
        <div id="app" ref="document">
            <div id="receiptdiv" class = 'w-3/4 mx-auto mt-5'>
                <div id="left">
                    <table>
                        <tr>
                            <td colspan="2"><Title :title = "$t('receiptpage.title')"/></td>
                        </tr>
                        <tr>
                            <td>WISMA TANAH DAN UKUR,</td>
                        </tr>
                        <tr>
                            <td>JALAN PERWIRA,</td>
                        </tr>
                        <tr>
                            <td>88200 KOTA KINABALU, SABAH,</td>
                        </tr>
                        <tr>
                            <td>MALAYSIA</td>
                        </tr>
                    </table>
                </div>
                <div id="right">
                    <br>
                    <table>
                        <tr>
                        </tr>
                        <tr>
                            <td style="font-weight: bold; text-align: right; padding-right: 5px;">{{$t('receiptpage.receiptno')}}</td>
                            <td>{{rctnum}}</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold; text-align: right; padding-right: 5px;">{{$t('receiptpage.titleno')}}</td>
                            <td>{{titleno}}</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold; text-align: right; padding-right: 5px;">{{$t('receiptpage.receiptdate')}}</td>
                            <td>{{this.dailym.rdate}}</td>
                        </tr>
                        <tr v-if="rctnum.includes('FPX')">
                            <td style="font-weight: bold; text-align: right; padding-right: 5px;">{{$t('receiptpage.fpxtxnid')}}</td>
                            <td>{{this.dailym.rctfr}}</td>
                        </tr>
                        <tr v-else>
                            <td style="font-weight: bold; text-align: right; padding-right: 5px;">{{$t('receiptpage.rctfr')}}</td>
                            <td>{{this.dailym.rctfr}}</td>
                        </tr>
                    </table>
                </div>
                <div id="center">
                    <img src = "@/assets/logo.png" class = 'object-contain h-36 mt-10'/>
                </div>
                <br><br>
                <div>
                    <table class="table_border" style="width: 100%;"> 
                        <tr class="table_border" style="font-weight: bold;">
                            <td class="table_border">{{$t('receiptpage.no')}}</td>
                            <td class="table_border">{{$t('receiptpage.description')}}</td>
                            <td class="table_border">{{$t('receiptpage.amount')}}</td>
                        </tr>
                        <tr class="table_border" v-for="(dd, index) in this.dailyd" :key="dd.rctnum">
                            <td class="table_border">{{ index + 1 }}</td>
                            <td class="table_border">{{ dd.paydesc }}</td>
                            <td class="table_border">{{ $n(dd.amt, 'decimal') }}</td>
                        </tr>
                        <tr>
                            <td class="table_border" colspan="2" style="font-weight: bold;text-align: right;">{{$t('receiptpage.totalamount')}}</td>
                            <td class="table_border">{{ $n(this.totalamount, 'decimal') }}</td>
                        </tr>
                    </table>
                    <br><br>
                    <table class="table_border" style="width: 100%;"> 
                        <tr class="table_border" style="font-weight: bold;">
                            <td class="table_border">{{$t('receiptpage.no')}}</td>
                            <td class="table_border">{{$t('receiptpage.paymentmode')}}</td>
                            <td class="table_border">{{$t('receiptpage.chequeno')}}</td>
                            <td class="table_border">{{$t('receiptpage.amount')}}</td>
                            <td class="table_border">{{$t('receiptpage.bankname')}}</td>
                        </tr>
                        <tr class="table_border" v-for="(pi, index) in this.payinfo" :key="pi.rctnum">
                            <td class="table_border">{{ index + 1 }}</td>
                            <td class="table_border">{{ pi.paymodedescr }}</td>
                            <td class="table_border">{{ pi.chequeno }}</td>
                            <td class="table_border">{{ $n(pi.amt, 'decimal') }}</td>
                            <td class="table_border">{{ pi.drawee }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class = 'mx-auto text-center mt-5'>
            <button @click = "downloadReceipt" class = 'm-1 bg-blue-500 hover:bg-blue-700 rounded p-2 text-white text-sm'>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
            </svg>
            <span class = 'hidden sm:inline-block ml-1'>{{ $t('receiptpage.download') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import router from '@/router'
import Title from '@/components/Title'
import IconButton from '@/components/IconButton'
import axios from 'axios'
import config from '@/config'
import html2pdf from "html2pdf.js";

export default {
    name: "Receipt",
    data: () => {
        return {
            rctnum: "",
            titleno: "",
            showspinner: false,
            dailym: [],
            dailyd: [],
            payinfo: [],
            totalamount: 0.00,
        }
    },
    mounted: function () {
        this.rctnum = this.$route.query.rctnum;
        this.titleno = this.$route.query.titleno;
        this.showspinner = this.$route.query.showspinner;

        // axios.get("" + "?rctnum=" + this.rctnum)
        //     .then(res => {
        //         this.showspinner = false;

        //         this.dailym = res.data.dailym;
        //         this.dailyd = res.data.dailyd;
        //         this.payinfo = res.data.payinfo;

        //         this.payinfo.forEach(pi => {
        //             this.totalamount += pi.amt;
        //         });
        //     })
    },
    components: {
        Title,
        IconButton,
        html2pdf
    },
    computed: {
        
    },
    methods: {
        downloadReceipt() {
			html2pdf(document.getElementById("receiptdiv"), {
  			    filename: "JTU_QUIT_RENT_" + this.rctnum,
                jsPDF: { orientation: 'landscape' }
			});
		},
    },
}
</script>
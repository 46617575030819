const messages = {
    'en':{
        title:'JTU Quit Rent Enquiry',
        home:'Home',
        faq:'FAQ',
        contactus:'Contact Us',
        link:'Web Links',
        fpxreceiptlink:'FPX Receipt',
        copyright:'Lands And Surveys Department Sabah. All Rights Reserved.',
        titleno:'Title No',
        here:'Here',
        print:'Print',
        pay:'Payment',
        showpayment:'Show Payments',
        moredetail:'More Details',
        makepaymentfpx:'Make Payment Through FPX',
        back:'Back',
        year:'Year',
        homepage:{
            title:"Quit Rent Enquiry",
            msg1:"If your title no is less than 9 characters, you can generate the full title no",
            msg2:"Please take note that it will take 3 working days for the payment updating process. If your payment is not updated into our system after 3 working days, kindly call us at 088-527600",
            officehour:"Office hours",
            cannotfind:"Sorry cannot find this Title No",
            noPI:"No payment instructions found for this Title No",
            lessthan9char:"Please enter 9 characters of Title No"
        },
        linkpage:{
            title:'Web Links'
        },
        contactuspage:{
            title:'Contact Us',
            contactphone:'Contact Us At',
            contactemail:'Or Email To',
        },
        faqpage:{
            title:'Frequent Ask Question',
            question1:'How to pay online?',
            question2:'Information Required to Pay',
            question3:'Operation Time for Internet Banking',
            question4:'Modes of Payment',
            question5:'How to check your outstanding?',
            question6:'FAQ on Quit Rent',
        },
        arrearspage:{
            noticetitle:'QUIT RENT PAYMENT STATEMENT',
            amount:'Amount',
            rent:'Rent',
            notice:'Notice',
            surcharge:'Surcharge',
            cfduemsg:'Accumulated Quit Rent Outstanding as at',
            curduemsg:'Current Quit Rent Outstanding',
            total:'Total Quit Rent Outstanding',
            totalpayment:'Total Payment Made',
            totalquitrentoutstanding:'Total Quit Rent Outstanding',
            cfpaymsg:'Accumulated Payment as at',
            needtopay:'Total Quit Rent Outstanding Need to Pay',
            discountmsg:'YEAR 2020 QUIT RENT DISCOUNTED 30%',
            recno:'Receipt No',
            date:'Date',
            cfnoticefeemsg:'Accumulated Notice Fee',
            cfpaynoticefeemsg:'Accumulated Notice Fee Paid as at',
            noticefeeneedtopay:'Total Notice Fee to Pay',
            cfsurcajmsg:'Accumulated Surcharge',
            cfpaysurcajmsg:'Accumulated Surcharge Paid as at',
            surcajneedtopay:'Total Surcharge to Pay',
            totalamount:'Overall Total Outstanding need to Pay',
            downloadstatement:'Download Statement',
            paymentdetails:'Payment Details',
            rentdetails:'Rent Details',
        },
        paymentpage:{
            title:'Quit Rent Payment Summary',
            quitrenttopay: 'Quit Rent to pay (RM)',
            noticefeetopay: 'Notice Fee to pay (RM)',
            surchargetopay: 'Surcharge to pay (RM)',
            amttopay: 'Amount to pay (RM)',
            amounttopay:"Quit Rent Due need to pay (RM)",
            amountnoticefeetopay:"Notice Fee Due need to pay (RM)",
            amountsurchargetopay:"Surcharge Due need to pay (RM)",
            amount:"Amount (RM)",
            payer:"Payer's Name",
            email:"Email",
            pay:'Proceed to Payment Gateway',
            minimumAmount:'Minimum Payment Amount is RM 10',
            redirect: '',
            done: 'Please continue your payment through the FPX portal',
            thanks: 'Thank You',
        },
        receiptpage:{
            title:'Receipt Copy',
            receiptno:'Receipt No:',
            titleno:'Title No:',
            receiptdate:'Receipt Date:',
            fpxtxnid:'FPX TXN ID:',
            rctfr:'Receive From:',
            collectiondistrict:'Collection District:',
            paymentdetails:'Payment Details:',
            no:'No',
            description:'Description',
            amount:'Amount (RM)',
            paymentmode:'Payment Mode',
            chequeno:'Cheque No',
            bankname:'Bank Name',
            totalamount:'Total Amount (RM)',
            download:'Download',
        },
        paymentsummarypage:{
            title: 'Payment Summary',
            status: 'Transaction Status',
            date: 'Transaction Date',
            fpxtxnid: 'FPX Transaction ID',
            refno: 'Reference No.',
            titleno: 'Title No.',
            amt: 'Payment Amount',
            name: 'Payer Name',
            email: 'Payer Email',
            bank: 'Bank',
            success: 'Success',
            pending: 'Pending',
            notfound: 'Record Not Found',
            return: 'Return to App',
        },
        checkreceiptpage:{
            title: 'Check Receipt',
            valid: 'RECEIPT VALID',
            invalid: 'RECEIPT INVALID',
            review: 'Please review the details of your receipt.',
            rctnum: 'Receipt No.',
            rdate: 'Receipt Date',
            refno: 'Reference No.',
            rctfr: 'Receive From',
            amt: 'Amount (RM)',
        },
        fpxreceiptpage:{
            title:'Download FPX Receipt',
            refno: 'Reference No.',
            download:'Download',
            notfound: 'Record Not Found',
            pending: 'Pending Confirmation',
            error: 'Error'
        }
    },
    'bm':{
        title:'JTU Pertanyaan Sewa Tanah',
        home:'Laman Utama',
        faq:'Soalan Lazim',
        contactus:'Alamat Perhubungan',
        link:'Pautan',
        fpxreceiptlink:'Resit FPX',
        copyright:'Jabatan Tanah dan Ukur Negeri Sabah. Hak Cipta Terpelihara.',
        titleno:'No Geran',
        here:'Sini',
        print:'Cetak',
        pay:'Bayar',
        showpayment:'Tunjuk Bayaran',
        moredetail:'Butiran Selanjut',
        makepaymentfpx:'Pembayaran Melalui FPX',
        back:'Kembali',
        year:'Tahun',
        homepage:{
            title:"Pertanyaan Sewa Tanah",
            msg1:"Sekiranya Nombor Geran anda kurang daripada 9 digit, anda boleh menyemak Nombor Geran penuh di",
            msg2:"Pembayaran akan diproses dalam masa 3 hari berkerja. Sekiranya rekod pembayaran tidak dikemaskini selepas 3 hari bekerja, sila hubungi talian pejabat JTU dinombor 088-527600.",
            officehour:"Masa Beroperasi",
            cannotfind:"Minta Maaf tidak jumpa No Geran tersebut",
            lessthan9char:"Sila masuk 9 digit No Geran"
        },
        linkpage:{
            title:'Pautan Laman Web'
        },
        contactuspage:{
            title:'Alamat Perhubungan',
            contactphone:'Hubungi Kami di Talian',
            contactemail:'Atau Email Kepada',
        },
        faqpage:{
            title:'Soalan Lazim',
            question1:'Bagaimana Membayar Secara online?',
            question2:'Maklumat Yang Diperlukan Untuk Pembayaran',
            question3:'Masa Operasi Bagi Perbankan Internet',
            question4:'Kaedah Pembayaran',
            question5:'Bagaimana Memeriksa Bayaran Tertunggak?',
            question6:'Soalan Lazim Berhubung Sistem Pertanyaan Tunggakan Sewa Tanah',
        },
        arrearspage:{
            noticetitle:'PENYATA PEMBAYARAN CUKAI TANAH',
            amount:'Amaun',
            rent:'Sewa',
            notice:'Notis',
            surcharge:'Surcaj',
            cfduemsg:'Jumlah Sewa Tanah Yang Terkumpul Pada',
            curduemsg:'Sewa Tanah Tahunan',
            total:'Jumlah Sewa Tanah',
            totalpayment:'Jumlah Bayaran Yang Dibuat',
            totalquitrentoutstanding:'Jumlah Sewa Tanah Tertunggak',
            cfpaymsg:'Jumlah Sewa Tanah Yang Telah Dibayar Setakat',
            needtopay:'Jumlah Sewa Tanah Yang Perlu Dibayar',
            discountmsg:'SEWA TANAH TAHUNAN 2020 DIKURANGKAN SEBANYAK 30%',
            recno:'No Resit',
            date:'Tarikh',
            cfnoticefeemsg:'Jumlah Yuran Notis Yang Terkumpul',
            cfpaynoticefeemsg:'Jumlah Yuran Notis Yang Dibayar setakat',
            noticefeeneedtopay:'Jumlah Yuran Notis Yang Perlu Dibayar',
            cfsurcajmsg:'Jumlah Surcaj Yang Terkumpul',
            cfpaysurcajmsg:'Jumlah Surcaj Yang Dibayar setakat',
            surcajneedtopay:'Jumlah Surcaj Yang Perlu Dibayar',
            totalamount:'Jumlah Tunggakan Yang Perlu Dibayar',
            downloadstatement:'Muat Turun Penyata',
            paymentdetails:'Butiran Pembayaran',
            rentdetails:'Butiran Sewa Tanah',
        },
        paymentpage:{
            title:'Ringkasan Bayaran Sewa Tanah',
            quitrenttopay: 'Cukai Tanah perlu dibayar (RM)',
            noticefeetopay: 'Yuran Notis perlu dibayar (RM)',
            surchargetopay: 'Surcaj perlu dibayar (RM)',
            amttopay: 'Jumlah perlu dibayar (RM)',
            amounttopay:"Amaun perlu dibayar",
            amount:"Amaun (RM)",
            payer:'Nama Pembayar',
            email:"Emel",
            pay:'Terus ke Gerbang Pembayaran',
            minimumAmount:'Jumlah Bayaran Minimum ialah RM 10',
            redirect: '',
            done: 'Sila teruskan pembayaran anda melalui portal FPX',
            thanks: 'Terima Kasih',
        },
        receiptpage:{
            title:'Salinan Resit'
        },
        paymentsummarypage:{
            title: 'Ringkasan Pembayaran',
            status: 'Status Transaksi',
            date: 'Tarikh Transaksi',
            fpxtxnid: 'ID Transaksi FPX',
            refno: 'No. Rujukan',
            titleno: 'No. Geran',
            amt: 'Jumlah Bayaran',
            name: 'Nama Pembayar',
            email: 'Emel Pembayar',
            bank: 'Bank',
            success: 'Berjaya',
            pending: 'Pending',
            notfound: 'Rekod tidak dijumpai',
            return: 'Kembali ke Aplikasi',
        },
        checkreceiptpage:{
            title: 'Semak Resit',
            valid: 'RESIT SAH',
            invalid: 'RESIT TIDAK SAH',
            review: 'Sila semak butiran resit anda.',
            rctnum: 'No. Resit',
            rdate: 'Tarikh Resit',
            refno: 'No. Rujukan',
            rctfr: 'Diterima Daripada',
            amt: 'Amaun (RM)',
        },
        fpxreceiptpage:{
            title:'Muat Turun Resit FPX',
            refno:'No. Rujukan',
            download:'Muat Turun',
            notfound: 'Rekod tidak dijumpai',
            pending: 'Menunggu Pengesahan',
            error: 'Ralat',
        }
    }
}

export default messages;
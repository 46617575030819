<template>
    <div class = 'fixed t-0 w-screen p-4'>
        <div class = 'float-left' v-show = "$route.name != 'Home'">
            <img src = '@/assets/logo.png' class = 'w-10 inline-block'/>
            <router-link to = "/" class = 'ml-5 font-bold text-green-600 hidden sm:inline-block'>JTU Quit Rent Enquiry</router-link>
        </div>
        <div class="float-right">
            <a class = 'm-4 cursor-pointer'
                :class="{'font-bold':$i18n.locale=='en'}"
                @click = "$i18n.locale = 'en'"
            >English</a>
            <a class = 'm-4 cursor-pointer'
                :class="{'font-bold':$i18n.locale=='bm'}"
                @click = "$i18n.locale = 'bm'"
            >Bahasa Malaysia</a>
        </div>
    </div>
</template>

<script>
export default{
    name: "Header",
}
</script>

import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Faq from '@/views/Faq'
import Link from '@/views/Link'
import ContactUs from '@/views/ContactUs'
import Arrears from '@/views/Arrears'
import Payment from '@/views/Payment'
import Receipt from '@/views/Receipt'
import FpxReceipt from '@/views/FpxReceipt'
import PaymentSummary from '@/views/PaymentSummary'
import CheckReceipt from '@/views/CheckReceipt'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/link',
    name: 'Link',
    component: Link
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/arrears',
    name: 'Arrears',
    component: Arrears
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/receipt',
    name: 'Receipt',
    component: Receipt
  },
  {
    path: '/fpx-receipt',
    name: 'FpxReceipt',
    component: FpxReceipt
  },
  {
    path: '/paymentsummary',
    name: 'PaymentSummary',
    component: PaymentSummary
  },
  {
    path: '/checkreceipt',
    name: 'CheckReceipt',
    component: CheckReceipt
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base:'/v2/',
  routes
})

export default router

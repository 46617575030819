<template>
    <div class="bg-gray-100 fixed bottom-0 text-gray-500 p-4 w-screen text-xs md:text-base">
        <div class = 'float-left'>
            <router-link to = "/" class = 'm-3'>{{ $t('home') }}</router-link>
            <router-link to = "/faq" class = 'm-3'>{{ $t('faq') }}</router-link>
            <router-link to = "/contactus" class = 'm-3'>{{ $t('contactus') }}</router-link>
            <router-link to = "/link" class = 'm-3'>{{ $t('link') }}</router-link>
            <router-link to = "/fpx-receipt" class = 'm-3'>{{ $t('fpxreceiptlink') }}</router-link>
        </div>
        <div class = 'float-right invisible md:visible'>
            <span>{{ copyright }}</span>
        </div>
    </div>
</template>

<script>
export default{
    name: "Footer",
    computed:{
        copyright(){
            return "©" + new Date().getFullYear() + " " + this.$t('copyright');
        }
    }
}
</script>

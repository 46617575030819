<template>
    <Title title = 'contactuspage.title'/>

    <p class = 'font-bold text-center'>{{ $t('contactuspage.contactphone') }}</p>
    <p class = 'text-center'>
        Tel: 088-527600<br/>
        Fax: 088-527603
    </p>
    <p class = 'text-center font-bold mt-5'>{{ $t('contactuspage.contactemail') }}</p>
    <p class = 'text-center'>
        <a class = 'text-blue-500' href = "mailto:quitrent@jtu.sabah.gov.my">quitrent@jtu.sabah.gov.my</a><br/>
        <a class = 'text-blue-500' href = "mailto:enquiry@jtu.sabah.gov.my">enquiry@jtu.sabah.gov.my</a>
    </p>
</template>

<script>
import Title from '@/components/Title'

export default {
    name:"ContactUs",
    components:{
        Title
    }
}
</script>
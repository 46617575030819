import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import messages from '@/messages.js'
import { createI18n } from 'vue-i18n'

const numberFormats = {
    'en':{
        currency:{
            style: 'currency', currency: 'USD', notation: 'standard'
        },
        decimal:{
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent:{
            style: 'percent', useGrouping: false
        }
    },
    'bm':{
        currency:{
            style: 'currency', currency: 'JPY', useGrouping: true, currencyDisplay: 'symbol'
        },
        decimal:{
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent:{
            style: 'percent', useGrouping: false
        }
    }
}

  
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale:'en',
    fallbackLocale:'bm',
    messages,
    numberFormats
})

const app = createApp(App)
    .use(i18n)
    .use(router)
    .mount('#app')

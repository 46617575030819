<template>
    <img v-if = "show" src = '@/assets/loading-buffering.gif' class = 'h-20 w-20 mx-auto mt-10'/>
</template>

<script>
export default {
    props:{
        show:false
    }
}
</script>
<template>
    <Title title = "checkreceiptpage.title"/>
    
    <Loading :show = "loading"/>

    <div v-if="!loading" class="mx-auto w-4/5 md:w-2/3 lg:w-1/4">
        <div v-if="!valid" class="bg-red-500 text-white rounded text-center p-2">
            {{$t('checkreceiptpage.invalid')}}
        </div>

        <div v-if="valid" class="bg-green-500 text-white rounded text-center p-2">
            {{$t('checkreceiptpage.valid')}}
        </div>

        <div v-if="valid" class="text-center my-5">
            {{$t('checkreceiptpage.review')}}
        </div>

        <hr class="my-5">

        <div class = 'grid grid-cols-2 mt-5' v-if = "receipt">
            <div class="text-right font-bold mr-3">{{$t('checkreceiptpage.rctnum')}}</div>
            <div class="ml-3 mb-5">{{receipt.rctnum}}</div>
            
            <div class="text-right font-bold mr-3">{{$t('checkreceiptpage.rdate')}}</div>
            <div class="ml-3 mb-5">{{receipt.rdate}}</div>

            <div class="text-right font-bold mr-3">{{$t('checkreceiptpage.refno')}}</div>
            <div class="ml-3 mb-5">{{receipt.refno}}</div>

            <div class="text-right font-bold mr-3">{{$t('checkreceiptpage.rctfr')}}</div>
            <div class="ml-3 mb-5">{{receipt.rctfr}}</div>

            <div class="text-right font-bold mr-3">{{$t('checkreceiptpage.amt')}}</div>
            <div class="ml-3 mb-5">{{receipt.amt}}</div>
        </div>
    </div>
    
</template>

<script>
import Title from '@/components/Title'
import Loading from '@/components/Loading'
import config from '@/config'
import axios from 'axios'

export default {
    name:"CheckReceipt",
    components: { Title, Loading },
    data() {
        return {
            q: "",
            r: "",
            loading: true,
            msg: "",
            receipt: null,
            valid: false,
        }
    },
    created() {
        this.q = this.$route.query.q;
        this.r = this.$route.query.r;

        const formData = new FormData();
        formData.append("q", this.q);
        formData.append("r", this.r);

        axios.post(config.CHKRECEIPT_URL, formData)
            .then(res => {

                if (res.data == 'INVALID') {
                    this.msg = "RECEIPT INVALID";
                    this.valid = false;
                } else {
                    this.msg = "RECEIPT VALID";
                    this.valid = true;
                    this.receipt = res.data;
                }

                this.loading = false;
            })
    }
}
</script>
<template>
    <h1 @click="display=!display" class = 'font-bold text-blue-400 cursor-pointer mt-3'>{{question}}</h1>
    <div v-show="display" class = 'p-3'>
        <slot></slot>
    </div>
    
</template>

<script>
export default {
    name:"FaqItem",
    props:{
        question:""
    },
    data:()=>{
        return {display:false}
    }
}
</script>
<template>
    <Title title = "faqpage.title"/>
    <div class = 'w-3/4 md:w-1/2 m-auto pb-12'>

        <FaqItem :question = "$t('faqpage.question1')">
            <div v-if="$i18n.locale=='en'">
                You must be an Alliance Bank Account Holder and also a registered user of Alliance Online. For the purpose of reconciliation with the Land Title Quit Rent records, you MUST type in the :- [Title Number No],[Notice Serial Number].
            </div>
            <div v-else>
                Anda hendaklah menjadi pemegang akaun Alliance Bank dan juga pengguna berdaftar kepada Alliance Online. Untuk tujuan pembayaran tunggakan Sewa tanah, anda hendaklah memasukkan : [Nombor Geran] , [Nombor Siri Notis].
            </div>
        </FaqItem>
        
        <FaqItem :question = "$t('faqpage.question2')">
            <div v-if="$i18n.locale=='en'">
                <b>In Person or By Company</b>
                <ol class="list-disc pl-5">
                    <li>Title Number</li>
                    <li>Payee Name & IC Number / Company Registration Number</li>
                    <li>Bring the notice LSF 1631 if you have received one.</li>
                </ol>
                <b>Online</b>
                <ol class="list-disc pl-5">
                    <li>Bank Account (Saving or Current Account)</li>
                    <li>Register as Alliance Online Internet Banking Account User www.allianceonline.com.my</li>
                    <li>Title Number & Notice Serial Number</li>
                    <li>Payee Name & IC Number / Company</li>
                    <li>Notice Serial Number Reference of LSF 1631. (Check online). This is a 10 digit number.</li>
                    <li>You must enter the Title number followed by a ',' and then the Notice Serial Number. This is very important information to ensure that your payment is effected and for the refund if applicable.</li>
                </ol>
            </div>
            <div v-else>
                <b>Individu atau Syarikat</b>
                <ol class="list-disc pl-5">
                    <li>Nombor Geran</li>
                    <li>Nama Pemilik & Nombor Kad Pengenalan / Nombor Pendaftaran Syarikat</li>
                    <li>Bawa bersama notis LSF 1631 jika anda menerimanya sebelum ini</li>
                </ol>
                <b>Online</b>
                <ol class="list-disc pl-5">
                    <li>Akaun Bank (Simpanan atau Akaun Semasa)</li>
                    <li>Berdaftar sebagai pengguna akaun Alliance Online Internet Banking di www.allianceonline.com.my</li>
                    <li>Nombor Geran & Nombor Siri Notis</li>
                    <li>Nama Pemilik & Nombor Kad Pengenalan / Syarikat</li>
                    <li>Rujukan Nombor Siri Notis LSF 1631.(Periksa online). Ini adalah nombor 10-digit.</li>
                    <li>Anda hendaklah memasukan Nombor Geran diikuti dengan tanda ',' dan kemudian Nombor Siri Notis. Maklumat ini adalah penting bagi memastikkan transaksi pembayaran dan pemulangan (jika perlu) dapat dilaksanakan.</li>
                </ol>
            </div>
        </FaqItem>

        <FaqItem :question = "$t('faqpage.question3')">
            <div v-if="$i18n.locale=='en'">
                <b class = 'text-red-600'>7:30 a.m. to 9:00 p.m. (Monday to Sunday)</b>
                <p>After the above time, you can only make enquiry on your account, no payment will be allowed.</p>
            </div>
            <div v-else>
                <b class = 'text-red-600'>7:30 a.m. to 9:00 p.m. (Isnin hingga Ahad)</b>
                <p>Selepas waktu yang tertera diatas, anda hanya boleh melakukan semakan akaun. Sebarang pembayaran tidak akan dibenarkan.</p>
            </div>
        </FaqItem>

        <FaqItem :question = "$t('faqpage.question4')">
            <div v-if="$i18n.locale=='en'">
                <b>Internet Banking</b>
                <ol class="list-disc pl-5">
                    <li>Alliance Online</li>
                </ol>
                <p class = 'font-bold pt-2'>Pay by Cash, Cashier Order, Bank Draft, Postal Order, Money Order</p>
                <ol class="list-disc pl-5">
                    <li>All PPHT Office Counters in Sabah</li>
                    <li>4th Floor, Branch Collection Office at Wisma Sabah, Kota Kinabalu</li>
                    <li>Alliance Bank Branches in Malaysia</li>
                </ol>
            </div>
            <div v-else>
                <b>Perbankan Internet</b>
                <ol class="list-disc pl-5">
                    <li>Alliance Online</li>
                </ol>
                <p class = 'font-bold pt-2'>Bayaran Tunai, Cashier Order, Bank Draft, Postal Order, Money Order</p>
                <ol class="list-disc pl-5">
                    <li>Semua kaunter pejabat PPHT di negeri Sabah</li>
                    <li>Tingkat 4, Cawangan Kutipan Hasil Tanah, Wisma Sabah, Kota Kinabalu</li>
                    <li>Cawangan Bank Alliance di Malaysia</li>
                </ol>
            </div>
        </FaqItem>

        <FaqItem :question = "$t('faqpage.question5')">
            <div v-if="$i18n.locale=='en'">
                You must enter the first 8 digits of your land title. You may enter the full 9 digits of the land title.
            </div>
            <div v-else>
                Masukkan 8-digit pertama Nombor Geran anda. Anda juga boleh memasukkan keseluruhan 9-digit Nombor Geran tersebut.
            </div>
        </FaqItem>

        <FaqItem :question = "$t('faqpage.question6')">
            <div v-if="$i18n.locale=='en'">
                <ol>
                <li>
                    <b>When is my quit rent due?</b>
                    <ol class="list-disc pl-5">
                        <li>Your quit rent is due on every 1st of January of the year.</li>
                    </ol>
                </li>
                <li class = 'pt-2'>
                    <b>How to request for refund for payment made on wrong title number?</b>
                    <ol class="list-disc pl-5">
                        <li>You must write in a letter with the explanation on the refund.</li>
                        <li>Copy of your IC.</li>
                        <li>Original Copy of the receipt.</li>
                    </ol>
                </li>
            </ol>
            </div>
            <div v-else>
                <ol>
                <li>
                    <b>Bilakah tarikh sewa tanah bermula?</b>
                    <ol class="list-disc pl-5">
                        <li>Tarikh sewa tanah bermula pada setiap 1hb Januari pada setiap tahun.</li>
                    </ol>
                </li>
                <li class = 'pt-2'>
                    <b>Bagaimanakah untuk mendapatkan semula wang pembayaran jika berlaku kesilapan memasukan Nombor Geran yang salah ketika pembayaran?</b>
                    <ol class="list-disc pl-5">
                        <li>Anda hendaklah mengemukakan surat penjelasan berhubung pengembalian wang.</li>
                        <li>Salinan Nombor Kad Pengenalan anda.</li>
                        <li>Salinan asal resit pembayaran sewa tanah.</li>
                    </ol>
                </li>
            </ol>
            </div>
        </FaqItem>
    </div>
</template>

<script>
import Title from '@/components/Title'
import FaqItem from '@/components/FaqItem'

export default {
    name:"Faq",
    components:{
        Title,
        FaqItem
    }
}
</script>
<template>
    <h1 class = 'text-2xl text-center font-bold p-5'>{{ $t(title) }}</h1>
</template>

<script>
export default {
    name:"Title",
    props:{
        title:""
    }
}
</script>
<template>
    <Title title = 'paymentsummarypage.title'/>

    <div class="mx-auto w-5/6 md:w-3/4 lg:w-2/5">
        <hr class="border-gray-700">
    </div>

    <img v-if="loading" src='@/assets/loading-buffering.gif' class='h-20 w-20 mx-auto mt-10'/>

    <div v-if="!loading" class="mx-auto w-4/5 md:w-2/3 lg:w-1/4">

        <div v-if="fpxPaymentStatus == 'PENDING'" class = 'grid grid-cols-2 gap-y-5 mt-5'> 
            <div class="font-bold">{{$t('paymentsummarypage.status')}}</div>
            <div class="bg-gray-300 w-fit px-3 py-1 rounded" style="width: fit-content;">
                {{$t('paymentsummarypage.pending')}}
            </div>    
        </div>

        <div v-if="fpxPaymentStatus == 'NOT FOUND'" class = 'grid grid-cols-2 gap-y-5 mt-5'> 
            <div class="col-span-2 text-center">
                {{$t('paymentsummarypage.notfound')}}
            </div>
        </div>

        <div v-if="fpxPaymentStatus == 'SUCCESS'" class = 'grid grid-cols-2 gap-y-5 mt-5'> 

            <div class="font-bold">{{$t('paymentsummarypage.status')}}</div>
            <div class="bg-green-600 w-fit text-white px-3 py-1 rounded" style="width: fit-content;">
                {{$t('paymentsummarypage.success')}}
            </div>

            <div class="font-bold">{{$t('paymentsummarypage.date')}}</div>
            <div>{{ transactionDate }}</div>

            <div class="font-bold">{{$t('paymentsummarypage.fpxtxnid')}}</div>
            <div>{{ fpxPayment.fpxtxnid }}</div>

            <div class="font-bold">{{$t('paymentsummarypage.refno')}}</div>
            <div>{{ fpxPayment.refno }}</div>

            <div class="font-bold">{{$t('paymentsummarypage.titleno')}}</div>
            <div>{{ fpxPayment.titleno }}</div>

            <div class="font-bold">{{$t('paymentsummarypage.amt')}}</div>
            <div>RM {{ $n(fpxPayment.amt, 'decimal') }}</div>

            <div class="font-bold">{{$t('paymentsummarypage.name')}}</div>
            <div>{{ fpxPayment.payername }}</div>

            <div class="font-bold">{{$t('paymentsummarypage.email')}}</div>
            <div>{{ fpxPayment.email }}</div>

            <div class="font-bold">{{$t('paymentsummarypage.bank')}}</div>
            <div>{{ fpxPayment.bankcode }}</div>

        </div>

    </div>
    
    <!-- <div v-if="isMobile" class="text-center mt-5">
        <button @click="closeWindow" class="bg-blue-500 text-white px-4 py-2 rounded">
            {{$t('paymentsummarypage.return')}}
        </button>
    </div> -->

</template>

<script>
import Title from '@/components/Title'
import axios from 'axios'
import config from '@/config'

export default {
    name:"PaymentSummary",
    data: () => {
        return {
            refno: "",
            loading: false,
            fpxPayment: [],
            fpxPaymentStatus: false,
            transactionDate: ""
        }
    },
    computed: {
        isMobile() {
            return /Mobi|Android/i.test(navigator.userAgent);
        }
    },
    mounted: function () {

        this.refno = this.$route.query.refno;

        this.loading = true;

        axios.get(config.FPX_STATUS_URL + "/" + this.refno)
        .then(res => {

            this.fpxPaymentStatus = res.data.msg;

            if (res.data.msg == "SUCCESS") {
                this.fpxPayment = res.data.fpxPayment;
                this.transactionDate = res.data.transactionDate;
            }

            this.loading = false;
        })
    },
    components:{
        Title
    },
    methods: {
        closeWindow() {
            window.close();
        }
    }
}

</script>
<template>
    <Title title = 'linkpage.title'/>
    <p class="text-center">
        <a class = 'text-blue-500' target="_blank" href = 'https://www.allianceonline.net.my/'>www.allianceonline.net.my</a>
    </p>
</template>

<script>
import Title from '@/components/Title'

export default {
    name:"Link",
    components:{
        Title
    }
}
</script>